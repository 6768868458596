import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import Button from "@mui/material/Button";
import { SnackbarAction, SnackbarKey, useSnackbar } from "notistack";

import { removeToast } from "_common/components/toast/actions";
import { Toast } from "_common/components/toast/types";

const Toaster: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toasts = useSelector<any, Toast[]>((state) => state.toast.toasts);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    toasts.forEach((toast) => {
      let action: SnackbarAction;

      if (toast.actionText) {
        action = (key: SnackbarKey) => (
          <Button
            onClick={() => {
              toast.onActionClick && toast.onActionClick({ history });
              closeSnackbar(key);
            }}
          >
            {toast.actionText}
          </Button>
        );
      }

      enqueueSnackbar(toast.text, {
        key: toast.id,
        variant: toast.status,
        autoHideDuration: toast.autoHideDuration,
        action,
      });

      dispatch(removeToast(toast.id));
    });
  }, [toasts]);

  return null;
};

export default withRouter(Toaster);
